import { httpClient } from '@/util/httpClient';
import { getUser } from './getUser';
import { setAuthToken } from '@/app/[lang]/@unauthorized/actions';

export const login = async (body: { email: string; password: string }) => {
  try {
    const resp = await httpClient().post('/auth/token', null, {
      auth: {
        username: body.email,
        password: body.password,
      },
    });

    if (resp.status === 200) {
      const token = resp.data.access_token;
      setAuthToken(token);

      await getUser(resp.data.client_id);
    }

    return { status: resp.status, data: resp.data };
  } catch (error) {
    const {
      response: {
        data: { detail },
      },
    } = error as { response: { data: { detail: string } } };

    return { status: 400, data: detail };
  }
};
